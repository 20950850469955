import { useMemo } from 'react'
import { useActiveNetwork } from '../contexts/Application'
import { getApolloClient, getBlockClient } from '../apollo/client'

export function useClients() {
  const [activeNetwork] = useActiveNetwork()

  return useMemo(() => {
    const dataClient = getApolloClient(activeNetwork)
    const blockClient = getBlockClient(activeNetwork)

    return {
      dataClient,
      blockClient,
    }
  }, [activeNetwork])
}
