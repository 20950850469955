import BERACHAIN_LOGO_URL from '../assets/berachain-logo.png'

export const SupportedNetwork = {
  // BERACHAIN_BARTIO_TESTNET: 'BERACHAIN_BARTIO_TESTNET',
  BERACHAIN: 'BERACHAIN',
}

// export const BerachainBArtioTestnetNetworkInfo = {
//   id: SupportedNetwork.BERACHAIN_BARTIO_TESTNET,
//   route: 'berachain_bartio',
//   name: 'Berachain bArtio',
//   bgColor: '#1E1B19',
//   primaryColor: '#3083DC',
//   secondaryColor: '#2172e5',
//   imageURL: BERACHAIN_LOGO_URL,
//   subgraphEndpoint:
//     'https://api.goldsky.com/api/public/project_clpx84oel0al201r78jsl0r3i/subgraphs/kodiak-v2-berachain-bartio/1.0.3/gn',
//   blockSubgraphEndpoint:
//     'https://api.goldsky.com/api/public/project_clpx84oel0al201r78jsl0r3i/subgraphs/bartio-blocks/1.0.0/gn',
// }

export const BerachainNetworkInfo = {
  id: SupportedNetwork.BERACHAIN,
  route: 'berachain_mainnet',
  name: 'Berachain',
  bgColor: '#1E1B19',
  primaryColor: '#3083DC',
  secondaryColor: '#2172e5',
  imageURL: BERACHAIN_LOGO_URL,
  subgraphEndpoint:
    'https://api.goldsky.com/api/public/project_clpx84oel0al201r78jsl0r3i/subgraphs/kodiak-v2-berachain-mainnet/latest/gn',
  blockSubgraphEndpoint:
    'https://api.goldsky.com/api/public/project_clpx84oel0al201r78jsl0r3i/subgraphs/berachain-mainnet-blocks/1.0.0/gn',
}

export const SUPPORTED_NETWORK_VERSIONS = [/*BerachainBArtioTestnetNetworkInfo,*/ BerachainNetworkInfo]

export const DEFAULT_NETWORK = BerachainNetworkInfo
