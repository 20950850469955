import { createContext, useContext, useReducer, useMemo, useCallback, useEffect, useState } from 'react'
import { useClients } from '../hooks/useNetworkClient'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useTimeframe } from './Application'
import {
  getPercentChange,
  getBlockFromTimestamp,
  getBlocksFromTimestamps,
  get2DayPercentChange,
  getTimeframe,
} from '../utils'
import {
  GLOBAL_DATA,
  GLOBAL_TXNS,
  GLOBAL_CHART,
  ETH_PRICE,
  ALL_PAIRS,
  ALL_TOKENS,
  TOP_LPS_PER_PAIRS,
} from '../apollo/queries'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import { useAllPairData } from './PairData'
import { useTokenChartDataCombined } from './TokenData'

// format dayjs with the libraries that we need
dayjs.extend(utc)
dayjs.extend(weekOfYear)

// Action Types
const UPDATE = 'UPDATE'
const UPDATE_TXNS = 'UPDATE_TXNS'
const UPDATE_CHART = 'UPDATE_CHART'
const UPDATE_ETH_PRICE = 'UPDATE_ETH_PRICE'
const ETH_PRICE_KEY = 'ETH_PRICE_KEY'
const UPDATE_ALL_PAIRS_IN_UNISWAP = 'UPDAUPDATE_ALL_PAIRS_IN_UNISWAPTE_TOP_PAIRS'
const UPDATE_ALL_TOKENS_IN_UNISWAP = 'UPDATE_ALL_TOKENS_IN_UNISWAP'
const UPDATE_TOP_LPS = 'UPDATE_TOP_LPS'

const offsetVolumes = []

const GlobalDataContext = createContext(null)

// Custom hook to use the context
export function useGlobalDataContext() {
  const context = useContext(GlobalDataContext)
  if (!context) {
    throw new Error('useGlobalDataContext must be used within a GlobalDataProvider')
  }
  return context
}

function reducer(state, { type, payload }) {
  switch (type) {
    case UPDATE: {
      const { data } = payload
      return {
        ...state,
        globalData: data,
      }
    }
    case UPDATE_TXNS: {
      const { transactions } = payload
      return {
        ...state,
        transactions,
      }
    }
    case UPDATE_CHART: {
      const { daily, weekly } = payload
      return {
        ...state,
        chartData: {
          daily,
          weekly,
        },
      }
    }
    case UPDATE_ETH_PRICE: {
      const { ethPrice, oneDayPrice, ethPriceChange } = payload
      return {
        [ETH_PRICE_KEY]: ethPrice,
        oneDayPrice,
        ethPriceChange,
      }
    }

    case UPDATE_ALL_PAIRS_IN_UNISWAP: {
      const { allPairs } = payload
      return {
        ...state,
        allPairs,
      }
    }

    case UPDATE_ALL_TOKENS_IN_UNISWAP: {
      const { allTokens } = payload
      return {
        ...state,
        allTokens,
      }
    }

    case UPDATE_TOP_LPS: {
      const { topLps } = payload
      return {
        ...state,
        topLps,
      }
    }
    default: {
      throw Error(`Unexpected action type in DataContext reducer: '${type}'.`)
    }
  }
}

export default function GlobalDataProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, {})

  const update = useCallback((data) => {
    dispatch({
      type: UPDATE,
      payload: { data },
    })
  }, [])

  const updateTransactions = useCallback((transactions) => {
    dispatch({
      type: UPDATE_TXNS,
      payload: { transactions },
    })
  }, [])

  const updateChart = useCallback((daily, weekly) => {
    dispatch({
      type: UPDATE_CHART,
      payload: { daily, weekly },
    })
  }, [])

  const updateEthPrice = useCallback((ethPrice, oneDayPrice, ethPriceChange) => {
    dispatch({
      type: UPDATE_ETH_PRICE,
      payload: { ethPrice, oneDayPrice, ethPriceChange },
    })
  }, [])

  const updateAllPairsInKodiak = useCallback((allPairs) => {
    dispatch({
      type: UPDATE_ALL_PAIRS_IN_UNISWAP,
      payload: { allPairs },
    })
  }, [])

  const updateAllTokensInKodiak = useCallback((allTokens) => {
    dispatch({
      type: UPDATE_ALL_TOKENS_IN_UNISWAP,
      payload: { allTokens },
    })
  }, [])

  const updateTopLps = useCallback((topLps) => {
    dispatch({
      type: UPDATE_TOP_LPS,
      payload: { topLps },
    })
  }, [])

  const contextValue = useMemo(
    () => [
      state,
      {
        update,
        updateTransactions,
        updateChart,
        updateEthPrice,
        updateTopLps,
        updateAllPairsInKodiak,
        updateAllTokensInKodiak,
      },
    ],
    [
      state,
      update,
      updateTransactions,
      updateTopLps,
      updateChart,
      updateEthPrice,
      updateAllPairsInKodiak,
      updateAllTokensInKodiak,
    ]
  )

  return <GlobalDataContext.Provider value={contextValue}>{children}</GlobalDataContext.Provider>
}

/**
 * Gets all the global data for the overview page.
 * Needs current eth price and the old eth price to get
 * 24 hour USD changes.
 * @param {*} ethPrice
 * @param {*} oldEthPrice
 */

async function getGlobalData(ethPrice, oldEthPrice, dataClient, blockClient) {
  let resultData = {}
  let oneDayData = {}
  let twoDayData = {}
  if (!dataClient || !blockClient) return resultData
  try {
    // get timestamps for the days
    const utcCurrentTime = dayjs()
    const startBlockTimestamp = 1718028409

    const utcOneDayBack = Math.max(utcCurrentTime.subtract(1, 'day').unix(), startBlockTimestamp)
    const utcTwoDaysBack = Math.max(utcCurrentTime.subtract(2, 'day').unix(), startBlockTimestamp)
    const utcOneWeekBack = Math.max(utcCurrentTime.subtract(1, 'week').unix(), startBlockTimestamp)
    const utcTwoWeeksBack = Math.max(utcCurrentTime.subtract(2, 'week').unix(), startBlockTimestamp)

    let [oneDayBlock, twoDayBlock, oneWeekBlock, twoWeekBlock] = await getBlocksFromTimestamps(blockClient, [
      utcOneDayBack,
      utcTwoDaysBack,
      utcOneWeekBack,
      utcTwoWeeksBack,
    ])
    let result = await dataClient.query({
      query: GLOBAL_DATA(),
      fetchPolicy: 'cache-first',
    })
    resultData = { ...result.data.uniswapFactories[0] }
    let oneDayResult = await dataClient.query({
      query: GLOBAL_DATA(oneDayBlock?.number),
      fetchPolicy: 'cache-first',
    })
    oneDayData = { ...oneDayResult.data.uniswapFactories[0] }
    let twoDayResult = await dataClient.query({
      query: GLOBAL_DATA(twoDayBlock?.number),
      fetchPolicy: 'cache-first',
    })
    twoDayData = { ...twoDayResult.data.uniswapFactories[0] }
    let oneWeekResult = await dataClient.query({
      query: GLOBAL_DATA(oneWeekBlock?.number),
      fetchPolicy: 'cache-first',
    })
    const oneWeekData = { ...oneWeekResult.data.uniswapFactories[0] }
    let twoWeekResult = await dataClient.query({
      query: GLOBAL_DATA(twoWeekBlock?.number),
      fetchPolicy: 'cache-first',
    })
    const twoWeekData = { ...twoWeekResult.data.uniswapFactories[0] }

    let oneDayVolumeUSD = 0
    let volumeChangeUSD = 0
    let oneWeekVolume = 0
    let weeklyVolumeChange = 0
    let oneDayTxns = 0
    let txnChange = 0
    if (resultData && oneDayData && twoDayData) {
      [oneDayVolumeUSD, volumeChangeUSD] = get2DayPercentChange(
        resultData.totalVolumeUSD,
        oneDayData.totalVolumeUSD,
        twoDayData.totalVolumeUSD
      )
    }
    if (resultData && oneWeekData && twoWeekData) {
      [oneWeekVolume, weeklyVolumeChange] = get2DayPercentChange(
        resultData.totalVolumeUSD,
        oneWeekData.totalVolumeUSD,
        twoWeekData.totalVolumeUSD
      )
    }

    if (resultData && oneDayData && twoDayData) {
      [oneDayTxns, txnChange] = get2DayPercentChange(
        resultData.txCount,
        oneDayData.txCount ? oneDayData.txCount : 0,
        twoDayData.txCount ? twoDayData.txCount : 0
      )
    }

    // Create new object with calculated values
    const enrichedData = {
      ...resultData,
      totalLiquidityUSD: resultData.totalLiquidityETH * ethPrice,
      oneDayVolumeUSD,
      oneWeekVolume,
      weeklyVolumeChange,
      volumeChangeUSD,
      liquidityChangeUSD: getPercentChange(
        resultData.totalLiquidityETH * ethPrice,
        oneDayData.totalLiquidityETH * oldEthPrice
      ),
      oneDayTxns,
      txnChange,
    }

    return enrichedData
  } catch (e) {
    console.error(e)
  }
  return resultData
}

/**
 * Get historical data for volume and liquidity used in global charts
 * on main page
 * @param {*} oldestDateToFetch // start of window to fetch from
 */

let checked = false

const getChartData = async (oldestDateToFetch, offsetData, dataClient) => {
  let chartData = []
  let weeklyData = []
  const utcEndTime = dayjs.utc()
  let skip = 0
  let allFound = false
  if (!dataClient) return [chartData, weeklyData]
  try {
    while (!allFound) {
      let result = await dataClient.query({
        query: GLOBAL_CHART,
        variables: {
          startTime: oldestDateToFetch,
          skip,
        },
        fetchPolicy: 'cache-first',
      })
      skip += 1000
      chartData = [...result.data.uniswapDayDatas]
      if (result.data.uniswapDayDatas.length < 1000) {
        allFound = true
      }
    }

    if (chartData) {
      let dayIndexSet = new Set()
      let dayIndexArray = []
      const oneDay = 24 * 60 * 60

      // Create new array with parsed values
      const parsedChartData = chartData.map((dayData, i) => {
        dayIndexSet.add((chartData[i].date / oneDay).toFixed(0))
        dayIndexArray.push(dayData)
        return {
          ...dayData,
          dailyVolumeUSD: parseFloat(dayData.dailyVolumeUSD),
        }
      })

      // Fill in empty days
      let timestamp = parsedChartData[0]?.date ? parsedChartData[0].date : oldestDateToFetch
      let latestLiquidityUSD = parsedChartData[0]?.totalLiquidityUSD
      let latestDayDats = parsedChartData[0]?.mostLiquidTokens
      let index = 1

      const filledChartData = [...parsedChartData]
      while (timestamp < utcEndTime.unix() - oneDay) {
        const nextDay = timestamp + oneDay
        let currentDayIndex = (nextDay / oneDay).toFixed(0)

        if (!dayIndexSet.has(currentDayIndex)) {
          filledChartData.push({
            date: nextDay,
            dailyVolumeUSD: 0,
            totalLiquidityUSD: latestLiquidityUSD,
            mostLiquidTokens: latestDayDats,
          })
        } else {
          latestLiquidityUSD = dayIndexArray[index].totalLiquidityUSD
          latestDayDats = dayIndexArray[index].mostLiquidTokens
          index = index + 1
        }
        timestamp = nextDay
      }

      // Sort data
      const sortedChartData = [...filledChartData].sort((a, b) => (parseInt(a.date) > parseInt(b.date) ? 1 : -1))

      // Process weekly data
      let startIndexWeekly = -1
      let currentWeek = -1

      const processedWeeklyData = []
      sortedChartData.forEach((entry, i) => {
        const date = sortedChartData[i].date

        // Handle offset volume
        let adjustedDailyVolumeUSD = parseFloat(sortedChartData[i].dailyVolumeUSD)
        if (offsetData && !checked) {
          offsetData.forEach((dayData) => {
            if (dayData[date]) {
              adjustedDailyVolumeUSD = adjustedDailyVolumeUSD - parseFloat(dayData[date].dailyVolumeUSD)
            }
          })
        }

        const week = dayjs.utc(dayjs.unix(sortedChartData[i].date)).week()
        if (week !== currentWeek) {
          currentWeek = week
          startIndexWeekly++
        }

        if (!processedWeeklyData[startIndexWeekly]) {
          processedWeeklyData[startIndexWeekly] = {
            date: date,
            weeklyVolumeUSD: adjustedDailyVolumeUSD,
          }
        } else {
          processedWeeklyData[startIndexWeekly].weeklyVolumeUSD += adjustedDailyVolumeUSD
        }
      })

      if (!checked) {
        checked = true
      }

      return [sortedChartData, processedWeeklyData]
    }
  } catch (e) {
    console.log(e)
  }
  return [chartData, weeklyData]
}

/**
 * Get and format transactions for global page
 */
const getGlobalTransactions = async (dataClient) => {
  let transactions = {}
  if (!dataClient) return transactions
  try {
    let result = await dataClient.query({
      query: GLOBAL_TXNS,
      fetchPolicy: 'cache-first',
    })
    transactions.mints = []
    transactions.burns = []
    transactions.swaps = []
    result?.data?.transactions &&
      result.data.transactions.map((transaction) => {
        if (transaction.mints.length > 0) {
          transaction.mints.map((mint) => {
            return transactions.mints.push(mint)
          })
        }
        if (transaction.burns.length > 0) {
          transaction.burns.map((burn) => {
            return transactions.burns.push(burn)
          })
        }
        if (transaction.swaps.length > 0) {
          transaction.swaps.map((swap) => {
            return transactions.swaps.push(swap)
          })
        }
        return true
      })
  } catch (e) {
    console.log(e)
  }

  return transactions
}

/**
 * Gets the current price  of ETH, 24 hour price, and % change between them
 */
const getEthPrice = async (dataClient, blockClient) => {
  const utcCurrentTime = dayjs()
  const utcOneDayBack = utcCurrentTime.subtract(1, 'day').startOf('minute').unix()

  let ethPrice = 0
  let ethPriceOneDay = 0
  let priceChangeETH = 0

  if (!dataClient) return [ethPrice, ethPriceOneDay, priceChangeETH]

  try {
    let oneDayBlock = await getBlockFromTimestamp(utcOneDayBack, blockClient)
    let result = await dataClient.query({
      query: ETH_PRICE(),
      fetchPolicy: 'cache-first',
    })
    let resultOneDay = await dataClient.query({
      query: ETH_PRICE(oneDayBlock),
      fetchPolicy: 'cache-first',
    })
    const currentPrice = result?.data?.bundles[0]?.ethPrice
    const oneDayBackPrice = resultOneDay?.data?.bundles[0]?.ethPrice
    priceChangeETH = getPercentChange(currentPrice, oneDayBackPrice)
    ethPrice = currentPrice
    ethPriceOneDay = oneDayBackPrice
  } catch (e) {
    console.log(e)
  }

  return [ethPrice, ethPriceOneDay, priceChangeETH]
}

const PAIRS_TO_FETCH = 500
const TOKENS_TO_FETCH = 500

/**
 * Loop through every pair on uniswap, used for search
 */
async function getAllPairsOnKodiak(dataClient) {
  try {
    let allFound = false
    let pairs = []
    let skipCount = 0
    if (!dataClient) return pairs
    while (!allFound) {
      let result = await dataClient.query({
        query: ALL_PAIRS,
        variables: {
          skip: skipCount,
        },
        fetchPolicy: 'cache-first',
      })
      skipCount = skipCount + PAIRS_TO_FETCH
      pairs = pairs.concat(result?.data?.pairs)
      if (result?.data?.pairs.length < PAIRS_TO_FETCH || pairs.length > PAIRS_TO_FETCH) {
        allFound = true
      }
    }
    return pairs
  } catch (e) {
    console.log(e)
  }
}

/**
 * Loop through every token on uniswap, used for search
 */
async function getAllTokensOnKodiak(dataClient) {
  try {
    let allFound = false
    let skipCount = 0
    let tokens = []
    if (!dataClient) return tokens
    while (!allFound) {
      let result = await dataClient.query({
        query: ALL_TOKENS,
        variables: {
          skip: skipCount,
        },
        fetchPolicy: 'cache-first',
      })
      tokens = tokens.concat(result?.data?.tokens)
      if (result?.data?.tokens?.length < TOKENS_TO_FETCH || tokens.length > TOKENS_TO_FETCH) {
        allFound = true
      }
      skipCount = skipCount += TOKENS_TO_FETCH
    }
    return tokens
  } catch (e) {
    console.log(e)
  }
}

/**
 * Hook that fetches overview data, plus all tokens and pairs for search
 */
export function useGlobalData() {
  const [state, { update, updateAllPairsInKodiak, updateAllTokensInKodiak }] = useGlobalDataContext()
  const [ethPrice, oldEthPrice] = useEthPrice()
  const { dataClient, blockClient } = useClients()
  const data = state?.globalData

  // const combinedVolume = useTokenDataCombined(offsetVolumes)

  useEffect(() => {
    async function fetchData() {
      let globalData = await getGlobalData(ethPrice, oldEthPrice, dataClient, blockClient)
      globalData && update(globalData)

      let allPairs = await getAllPairsOnKodiak(dataClient)
      updateAllPairsInKodiak(allPairs)

      let allTokens = await getAllTokensOnKodiak(dataClient)
      updateAllTokensInKodiak(allTokens)
    }
    if (!data && ethPrice && oldEthPrice) {
      fetchData()
    }
  }, [ethPrice, oldEthPrice, update, data, updateAllPairsInKodiak, updateAllTokensInKodiak, dataClient, blockClient])

  return data || {}
}

export function useGlobalChartData() {
  const [state, { updateChart }] = useGlobalDataContext()
  const [oldestDateFetch, setOldestDateFetched] = useState()
  const [activeWindow] = useTimeframe()
  const { dataClient } = useClients()

  const chartDataDaily = state?.chartData?.daily
  const chartDataWeekly = state?.chartData?.weekly

  /**
   * Keep track of oldest date fetched. Used to
   * limit data fetched until its actually needed.
   * (dont fetch year long stuff unless year option selected)
   */
  useEffect(() => {
    // based on window, get starttime
    let startTime = getTimeframe(activeWindow)

    if ((activeWindow && startTime < oldestDateFetch) || !oldestDateFetch) {
      setOldestDateFetched(startTime)
    }
  }, [activeWindow, oldestDateFetch])

  // fix for rebass tokens

  const combinedData = useTokenChartDataCombined(offsetVolumes, dataClient)

  /**
   * Fetch data if none fetched or older data is needed
   */
  useEffect(() => {
    async function fetchData() {
      // historical stuff for chart
      let [newChartData, newWeeklyData] = await getChartData(oldestDateFetch, combinedData, dataClient)
      updateChart(newChartData, newWeeklyData)
    }
    if (oldestDateFetch && !(chartDataDaily && chartDataWeekly) && combinedData) {
      fetchData()
    }
  }, [chartDataDaily, chartDataWeekly, combinedData, oldestDateFetch, updateChart, dataClient])

  return [chartDataDaily, chartDataWeekly]
}

export function useGlobalTransactions() {
  const [state, { updateTransactions }] = useGlobalDataContext()
  const transactions = state?.transactions
  const { dataClient } = useClients()

  useEffect(() => {
    async function fetchData() {
      if (!transactions) {
        let txns = await getGlobalTransactions(dataClient)
        updateTransactions(txns)
      }
    }
    fetchData()
  }, [updateTransactions, transactions, dataClient])
  return transactions
}

export function useEthPrice() {
  const [state, { updateEthPrice }] = useGlobalDataContext()
  const ethPrice = state?.[ETH_PRICE_KEY]
  const ethPriceOld = state?.['oneDayPrice']
  const { dataClient, blockClient } = useClients()

  useEffect(() => {
    async function checkForEthPrice() {
      if (!ethPrice) {
        let [newPrice, oneDayPrice, priceChange] = await getEthPrice(dataClient, blockClient)
        updateEthPrice(newPrice, oneDayPrice, priceChange)
      }
    }
    checkForEthPrice()
  }, [ethPrice, updateEthPrice, dataClient, blockClient])

  return [ethPrice, ethPriceOld]
}

export function useAllPairsInKodiak() {
  const [state] = useGlobalDataContext()
  let allPairs = state?.allPairs

  return allPairs || []
}

export function useAllTokensInKodiak() {
  const [state] = useGlobalDataContext()
  let allTokens = state?.allTokens

  return allTokens || []
}

/**
 * Get the top liquidity positions based on USD size
 * @TODO Not a perfect lookup needs improvement
 */
export function useTopLps(dataClient) {
  const [state, { updateTopLps }] = useGlobalDataContext()
  let topLps = state?.topLps

  const allPairs = useAllPairData()

  useEffect(() => {
    async function fetchData() {
      // get top 20 by reserves
      let topPairs = Object.keys(allPairs)
        ?.sort((a, b) => parseFloat(allPairs[a].reserveUSD > allPairs[b].reserveUSD ? -1 : 1))
        ?.slice(0, 99)
        .map((pair) => pair)

      let topLpLists = await Promise.all(
        topPairs.map(async (pair) => {
          // for each one, fetch top LPs
          try {
            const { data: results } = await dataClient.query({
              query: TOP_LPS_PER_PAIRS,
              variables: {
                pair: pair.toString(),
              },
              fetchPolicy: 'cache-first',
            })
            if (results) {
              return results.liquidityPositions
            }
          } catch (e) {
            console.log(e)
          }
        })
      )

      // get the top lps from the results formatted
      const topLps = []
      topLpLists
        .filter((i) => !!i) // check for ones not fetched correctly
        .map((list) => {
          return list.map((entry) => {
            const pairData = allPairs[entry.pair.id]
            return topLps.push({
              user: entry.user,
              pairName: pairData.token0.symbol + '-' + pairData.token1.symbol,
              pairAddress: entry.pair.id,
              token0: pairData.token0.id,
              token1: pairData.token1.id,
              usd:
                (parseFloat(entry.liquidityTokenBalance) / parseFloat(pairData.totalSupply)) *
                parseFloat(pairData.reserveUSD),
            })
          })
        })

      const sorted = topLps.sort((a, b) => (a.usd > b.usd ? -1 : 1))
      const shorter = sorted.splice(0, 100)
      updateTopLps(shorter)
    }

    if (!topLps && allPairs && Object.keys(allPairs).length > 0) {
      fetchData()
    }
  })

  return topLps
}
