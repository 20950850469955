import React, { useEffect, useRef, useState } from 'react'
import { HeaderProvider, RawHeader, useActiveLink } from '@kodiak-finance/ui-header'
import { useDarkModeManager } from '../../contexts/LocalStorage'
import Toggle from '../Toggle'
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { DEFAULT_NETWORK, SUPPORTED_NETWORK_VERSIONS } from '../../constants/networks'

const BaseKodiakHeader = ({ activeLink, setActiveLink }) => {
  const [isDark, toggleDarkMode] = useDarkModeManager()
  const location = useLocation()

  // Set initial active link and update on route change
  useEffect(() => {
    const setActivePage = () => {
      const cleanPath = location.pathname.split('?')[0]
      const page = cleanPath.split('/')[1]

      // Handle both root path and /home as home page
      if (!page || page === '' || page === 'home') {
        setActiveLink('nav-link-home')
      } else if (page === 'tokens' || page === 'token') {
        setActiveLink('nav-link-tokens')
      } else if (page === 'pairs' || page === 'pair') {
        setActiveLink('nav-link-pairs')
      } else if (page === 'account' || page === 'accounts') {
        setActiveLink('nav-link-accounts')
      }
    }

    setActivePage()
  }, [location, setActiveLink])

  return (
    <RawHeader>
      {/* <NetworkDropdown /> */}
      <Toggle isActive={isDark} toggle={toggleDarkMode} />
    </RawHeader>
  )
}

const KodiakHeader = () => {
  const [isDark] = useDarkModeManager()
  const { search, pathname } = useLocation()
  const params = new URLSearchParams(search)
  const [activeLink, setActiveLink] = useState('nav-link-home')

  // Ensure chain param is present
  if (!params.has('chain')) {
    params.set('chain', DEFAULT_NETWORK.route)
  }
  const updatedSearch = `?${params.toString()}`

  return (
    <HeaderProvider
      component={RouterLink}
      isDark={isDark}
      activeLink={activeLink}
      schema={[
        {
          to: { pathname: '/home', search: updatedSearch },
          id: 'nav-link-home',
          text: 'Home',
        },
        {
          to: { pathname: '/tokens', search: updatedSearch },
          id: 'nav-link-tokens',
          text: 'Tokens',
        },
        {
          to: { pathname: '/pairs', search: updatedSearch },
          id: 'nav-link-pairs',
          text: 'Pairs',
        },
        {
          to: { pathname: '/accounts', search: updatedSearch },
          id: 'nav-link-accounts',
          text: 'Accounts',
        },
        {
          text: 'About Kodiak',
          links: [
            {
              to: 'https://kodiak.finance',
              text: 'Kodiak Finance',
            },
            {
              to: process.env.REACT_APP_V3_ANALYTICS,
              text: 'V3 Analytics',
            },
            {
              to: '#',
              text: 'Docs',
            },
            {
              to: 'https://discord.gg/kodiak',
              text: 'Discord',
            },
            {
              to: 'https://x.com/KodiakFi',
              text: 'Twitter',
            },
          ],
        },
      ]}
    >
      <BaseKodiakHeader activeLink={activeLink} setActiveLink={setActiveLink} />
    </HeaderProvider>
  )
}

export default KodiakHeader
