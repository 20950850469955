import React, { useState } from 'react'
import styled from 'styled-components'

import { ApolloProvider } from '@apollo/client'
import { Route, Switch, Redirect } from 'react-router-dom'
import { useClients } from './hooks/useNetworkClient'
import GlobalPage from './pages/GlobalPage'
import TokenPage from './pages/TokenPage'
import PairPage from './pages/PairPage'
import { useGlobalData, useGlobalChartData } from './contexts/GlobalData'
import { isAddress } from './utils'
import AccountPage from './pages/AccountPage'
import AllTokensPage from './pages/AllTokensPage'
import AllPairsPage from './pages/AllPairsPage'
import PinnedData from './components/PinnedData'
import AccountLookup from './pages/AccountLookup'
import Meta from './components/Meta'
import LocalLoader from './components/LocalLoader'
import { useLatestBlocks, useActiveNetwork } from './contexts/Application'
import GoogleAnalyticsReporter from './components/analytics/GoogleAnalyticsReporter'
import { PAIR_BLACKLIST, TOKEN_BLACKLIST } from './constants'
import { useDarkModeManager } from './contexts/LocalStorage'
import Toggle from './components/Toggle'
import Link, { CustomLink } from './components/Link'
import KodiakHeader from './components/KodiakHeader'
import { TokenLogosProvider } from './contexts/TokenLogos'
import { DEFAULT_NETWORK } from './constants/networks'

const AppWrapper = styled.div`
  position: relative;
  width: 100%;
`
const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ open }) => (open ? '220px 1fr 200px' : '220px 1fr 64px')};

  @media screen and (max-width: 1400px) {
    grid-template-columns: 220px 1fr;
  }

  @media screen and (max-width: 1080px) {
    grid-template-columns: 1fr;
    max-width: 100vw;
    overflow: hidden;
    grid-gap: 0;
  }
`

const Right = styled.div`
  position: fixed;
  right: 0;
  bottom: 0rem;
  z-index: 99;
  width: ${({ open }) => (open ? '220px' : '64px')};
  height: ${({ open }) => (open ? 'fit-content' : '64px')};
  overflow: auto;
  /* background-color: ${({ theme }) => theme.bg1}; */
  @media screen and (max-width: 1400px) {
    display: none;
  }
`

const Center = styled.div`
  height: 100%;
  z-index: 9999;
  transition: width 0.25s ease;
  background-color: ${({ theme }) => theme.onlyLight};
`

const WarningWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const WarningBanner = styled.div`
  background-color: #ff6871;
  padding: 1.5rem;
  color: white;
  width: 100%;
  text-align: center;
  font-weight: 500;
`

/**
 * Wrap the component with the header and sidebar pinned tab
 */
const LayoutWrapper = ({ children, savedOpen, setSavedOpen }) => {
  const [isDark, toggleDarkMode] = useDarkModeManager()

  return (
    <>
      <Meta />
      <Center id="center">
        <KodiakHeader />
        {children}
      </Center>
      <Right open={savedOpen}>
        <PinnedData open={savedOpen} setSavedOpen={setSavedOpen} />
      </Right>
    </>
  )
}

const BLOCK_DIFFERENCE_THRESHOLD = 30

function App() {
  const [savedOpen, setSavedOpen] = useState(false)
  const { dataClient } = useClients()
  const globalData = useGlobalData()
  const globalChartData = useGlobalChartData()
  const [latestBlock, headBlock] = useLatestBlocks()
  const activeNetwork = useActiveNetwork()
  const showWarning = headBlock && latestBlock ? headBlock - latestBlock > BLOCK_DIFFERENCE_THRESHOLD : false

  return (
    <ApolloProvider client={dataClient}>
      <TokenLogosProvider>
        <AppWrapper>
          {showWarning && (
            <WarningWrapper>
              <WarningBanner>
                {`Warning: The data on this site has only synced to Berachain block ${latestBlock} (out of ${headBlock}). Please check back soon.`}
              </WarningBanner>
            </WarningWrapper>
          )}
          {globalData &&
          Object.keys(globalData).length > 0 &&
          globalChartData &&
          Object.keys(globalChartData).length > 0 ? (
            <>
              <Route component={GoogleAnalyticsReporter} />
              <Switch>
                <Route
                  exact
                  strict
                  path="/token/:tokenAddress"
                  render={({ match, location }) => {
                    if (
                      isAddress(match.params.tokenAddress.toLowerCase()) &&
                      !Object.keys(TOKEN_BLACKLIST).includes(match.params.tokenAddress.toLowerCase())
                    ) {
                      return (
                        <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                          <TokenPage address={match.params.tokenAddress.toLowerCase()} />
                        </LayoutWrapper>
                      )
                    } else {
                      return <Redirect to={{ pathname: '/home', search: location.search }} />
                    }
                  }}
                />
                <Route
                  exact
                  strict
                  path="/pair/:pairAddress"
                  render={({ match, location }) => {
                    if (
                      isAddress(match.params.pairAddress.toLowerCase()) &&
                      !Object.keys(PAIR_BLACKLIST).includes(match.params.pairAddress.toLowerCase())
                    ) {
                      return (
                        <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                          <PairPage pairAddress={match.params.pairAddress.toLowerCase()} />
                        </LayoutWrapper>
                      )
                    } else {
                      return <Redirect to={{ pathname: '/home', search: location.search }} />
                    }
                  }}
                />
                <Route
                  exact
                  strict
                  path="/account/:accountAddress"
                  render={({ match, location }) => {
                    if (isAddress(match.params.accountAddress.toLowerCase())) {
                      return (
                        <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                          <AccountPage account={match.params.accountAddress.toLowerCase()} />
                        </LayoutWrapper>
                      )
                    } else {
                      return <Redirect to={{ pathname: '/home', search: location.search }} />
                    }
                  }}
                />

                <Route path="/home">
                  <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                    <GlobalPage />
                  </LayoutWrapper>
                </Route>

                <Route path="/tokens">
                  <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                    <AllTokensPage />
                  </LayoutWrapper>
                </Route>

                <Route path="/pairs">
                  <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                    <AllPairsPage />
                  </LayoutWrapper>
                </Route>

                <Route path="/accounts">
                  <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                    <AccountLookup />
                  </LayoutWrapper>
                </Route>

                <Redirect
                  to={{
                    pathname: '/home',
                    search: activeNetwork.route !== DEFAULT_NETWORK.route ? `?chain=${activeNetwork.route}` : '',
                  }}
                />
              </Switch>
            </>
          ) : (
            <LocalLoader fill="true" />
          )}
        </AppWrapper>
      </TokenLogosProvider>
    </ApolloProvider>
  )
}

export default App
