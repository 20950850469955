import React, { createContext, useContext, useReducer, useMemo, useCallback, useState, useEffect } from 'react'
import { timeframeOptions, SUPPORTED_LIST_URLS__NO_ENS } from '../constants'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import getTokenList from '../utils/tokenLists'
import { SUBGRAPH_HEALTH } from '../apollo/queries'
import { useLocation } from 'react-router-dom'
import { SUPPORTED_NETWORK_VERSIONS, DEFAULT_NETWORK } from '../constants/networks'
dayjs.extend(utc)

const UPDATE = 'UPDATE'
const UPDATE_TIMEFRAME = 'UPDATE_TIMEFRAME'
const UPDATE_SESSION_START = 'UPDATE_SESSION_START'
const UPDATED_SUPPORTED_TOKENS = 'UPDATED_SUPPORTED_TOKENS'
const UPDATE_LATEST_BLOCK = 'UPDATE_LATEST_BLOCK'
const UPDATE_HEAD_BLOCK = 'UPDATE_HEAD_BLOCK'
const UPDATE_NETWORK = 'UPDATE_NETWORK'

const SUPPORTED_TOKENS = 'SUPPORTED_TOKENS'
const TIME_KEY = 'TIME_KEY'
const CURRENCY = 'CURRENCY'
const SESSION_START = 'SESSION_START'
const LATEST_BLOCK = 'LATEST_BLOCK'
const HEAD_BLOCK = 'HEAD_BLOCK'
const NETWORK = 'NETWORK'

const ApplicationContext = createContext()

function useApplicationContext() {
  return useContext(ApplicationContext)
}

function reducer(state, { type, payload }) {
  switch (type) {
    case UPDATE: {
      const { currency } = payload
      return {
        ...state,
        [CURRENCY]: currency,
      }
    }
    case UPDATE_TIMEFRAME: {
      const { newTimeFrame } = payload
      return {
        ...state,
        [TIME_KEY]: newTimeFrame,
      }
    }
    case UPDATE_SESSION_START: {
      const { timestamp } = payload
      return {
        ...state,
        [SESSION_START]: timestamp,
      }
    }

    case UPDATE_LATEST_BLOCK: {
      const { block } = payload
      return {
        ...state,
        [LATEST_BLOCK]: block,
      }
    }

    case UPDATE_HEAD_BLOCK: {
      const { block } = payload
      return {
        ...state,
        [HEAD_BLOCK]: block,
      }
    }

    case UPDATED_SUPPORTED_TOKENS: {
      const { supportedTokens } = payload
      return {
        ...state,
        [SUPPORTED_TOKENS]: supportedTokens,
      }
    }

    case UPDATE_NETWORK: {
      const { activeNetwork } = payload
      return {
        ...state,
        activeNetwork,
      }
    }

    default: {
      throw Error(`Unexpected action type in DataContext reducer: '${type}'.`)
    }
  }
}

const INITIAL_STATE = {
  CURRENCY: 'USD',
  TIME_KEY: timeframeOptions.ALL_TIME,
  activeNetwork: DEFAULT_NETWORK,
  latestBlock: null,
  headBlock: null,
}

export default function Provider({ children }) {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)
  const location = useLocation()

  // Update network based on query parameter and ensure default chain is always present
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const chainFromQuery = params.get('chain')

    // If no chain in query params, add the default chain to URL
    if (!chainFromQuery) {
      const newParams = new URLSearchParams(location.search)
      newParams.set('chain', DEFAULT_NETWORK.route)
      const newSearch = newParams.toString()
      window.history.replaceState(null, '', `${location.pathname}${newSearch ? `?${newSearch}` : ''}`)
      dispatch({
        type: UPDATE_NETWORK,
        payload: { activeNetwork: DEFAULT_NETWORK },
      })
      return
    }

    // Handle existing chain parameter
    const networkFromParam = SUPPORTED_NETWORK_VERSIONS.find(
      (n) => n.route.toLowerCase() === chainFromQuery.toLowerCase()
    )
    if (networkFromParam) {
      dispatch({
        type: UPDATE_NETWORK,
        payload: { activeNetwork: networkFromParam },
      })
      return
    }

    // If chain param is invalid, replace with default
    const newParams = new URLSearchParams(location.search)
    newParams.set('chain', DEFAULT_NETWORK.route)
    const newSearch = newParams.toString()
    window.history.replaceState(null, '', `${location.pathname}${newSearch ? `?${newSearch}` : ''}`)
    dispatch({
      type: UPDATE_NETWORK,
      payload: { activeNetwork: DEFAULT_NETWORK },
    })
  }, [location.search, location.pathname])

  const update = useCallback((currency) => {
    dispatch({
      type: UPDATE,
      payload: {
        currency,
      },
    })
  }, [])

  // global time window for charts - see timeframe options in constants
  const updateTimeframe = useCallback((newTimeFrame) => {
    dispatch({
      type: UPDATE_TIMEFRAME,
      payload: {
        newTimeFrame,
      },
    })
  }, [])

  // used for refresh button
  const updateSessionStart = useCallback((timestamp) => {
    dispatch({
      type: UPDATE_SESSION_START,
      payload: {
        timestamp,
      },
    })
  }, [])

  const updateSupportedTokens = useCallback((supportedTokens) => {
    dispatch({
      type: UPDATED_SUPPORTED_TOKENS,
      payload: {
        supportedTokens,
      },
    })
  }, [])

  const updateLatestBlock = useCallback((block) => {
    dispatch({
      type: UPDATE_LATEST_BLOCK,
      payload: {
        block,
      },
    })
  }, [])

  const updateHeadBlock = useCallback((block) => {
    dispatch({
      type: UPDATE_HEAD_BLOCK,
      payload: {
        block,
      },
    })
  }, [])

  const updateNetwork = useCallback((activeNetwork) => {
    dispatch({
      type: UPDATE_NETWORK,
      payload: { activeNetwork },
    })
  }, [])

  return (
    <ApplicationContext.Provider
      value={useMemo(
        () => [
          state,
          {
            update,
            updateSessionStart,
            updateTimeframe,
            updateSupportedTokens,
            updateLatestBlock,
            updateHeadBlock,
            updateNetwork,
          },
        ],
        [
          state,
          update,
          updateTimeframe,
          updateSessionStart,
          updateSupportedTokens,
          updateLatestBlock,
          updateHeadBlock,
          updateNetwork,
        ]
      )}
    >
      {children}
    </ApplicationContext.Provider>
  )
}

export function useLatestBlocks() {
  const [state, { updateLatestBlock, updateHeadBlock }] = useApplicationContext()
  return [state.latestBlock, state.headBlock, updateLatestBlock, updateHeadBlock]
}

export function useCurrentCurrency() {
  const [state, { update }] = useApplicationContext()
  const toggleCurrency = useCallback(() => {
    if (state.currency === 'ETH') {
      update('USD')
    } else {
      update('ETH')
    }
  }, [state, update])
  return [state[CURRENCY], toggleCurrency]
}

export function useTimeframe() {
  const [state, { updateTimeframe }] = useApplicationContext()
  const activeTimeframe = state?.[`TIME_KEY`]
  return [activeTimeframe, updateTimeframe]
}

export function useStartTimestamp() {
  const [activeWindow] = useTimeframe()
  const [startDateTimestamp, setStartDateTimestamp] = useState()

  // monitor the old date fetched
  useEffect(() => {
    let startTime =
      dayjs
        .utc()
        .subtract(
          1,
          activeWindow === timeframeOptions.week ? 'week' : activeWindow === timeframeOptions.ALL_TIME ? 'year' : 'year'
        )
        .startOf('day')
        .unix() - 1
    // if we find a new start time less than the current startrtime - update oldest pooint to fetch
    setStartDateTimestamp(startTime)
  }, [activeWindow, startDateTimestamp])

  return startDateTimestamp
}

// keep track of session length for refresh ticker
export function useSessionStart() {
  const [state, { updateSessionStart }] = useApplicationContext()
  const sessionStart = state?.[SESSION_START]

  useEffect(() => {
    if (!sessionStart) {
      updateSessionStart(Date.now())
    }
  })

  const [seconds, setSeconds] = useState(0)

  useEffect(() => {
    let interval = null
    interval = setInterval(() => {
      setSeconds(Date.now() - sessionStart ?? Date.now())
    }, 1000)

    return () => clearInterval(interval)
  }, [seconds, sessionStart])

  return parseInt(seconds / 1000)
}

export function useListedTokens() {
  const [state, { updateSupportedTokens }] = useApplicationContext()
  const supportedTokens = state?.[SUPPORTED_TOKENS]

  useEffect(() => {
    async function fetchList() {
      const allFetched = await SUPPORTED_LIST_URLS__NO_ENS.reduce(async (fetchedTokens, url) => {
        const tokensSoFar = await fetchedTokens
        const newTokens = await getTokenList(url)
        if (newTokens?.tokens) {
          return Promise.resolve([...tokensSoFar, ...(newTokens.tokens || [])])
        }
        return tokensSoFar
      }, Promise.resolve([]))

      // Create new array with formatted addresses
      const formatted = allFetched?.map((t) => t.address.toLowerCase())
      updateSupportedTokens(formatted)
    }
    if (!supportedTokens) {
      try {
        fetchList()
      } catch {
        console.log('Error fetching')
      }
    }
  }, [updateSupportedTokens, supportedTokens])

  return supportedTokens
}

export function useActiveNetwork() {
  const [state, { updateNetwork }] = useApplicationContext()
  return [state.activeNetwork, updateNetwork]
}
