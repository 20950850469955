import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client'
// BERA MIGRATION

export function getApolloClient(networkInfo) {
  return new ApolloClient({
    uri: networkInfo.subgraphEndpoint,
    cache: new InMemoryCache(),
    shouldBatch: true,
    queryDeduplication: true,
  })
}

export function getBlockClient(networkInfo) {
  return new ApolloClient({
    link: new HttpLink({
      uri: networkInfo.blockSubgraphEndpoint,
    }),
    cache: new InMemoryCache(),
  })
}

// Default clients for backward compatibility
export const client = getApolloClient({
  subgraphEndpoint:
    'https://api.goldsky.com/api/public/project_clpx84oel0al201r78jsl0r3i/subgraphs/kodiak-v2-berachain-mainnet/1.0.3/gn',
})

export const blockClient = getBlockClient({
  blockSubgraphEndpoint:
    'https://api.goldsky.com/api/public/project_clpx84oel0al201r78jsl0r3i/subgraphs/berachain-mainnet-blocks/1.0.0/gn',
})

// ---------Berachain Mainnet----------------

export const berachainMainnetClient = new ApolloClient({
  uri: 'https://api.goldsky.com/api/public/project_clpx84oel0al201r78jsl0r3i/subgraphs/kodiak-v2-berachain-mainnet/latest/gn',

  cache: new InMemoryCache({
    typePolicies: {
      Token: {
        keyFields: false,
      },
      Pool: {
        keyFields: false,
      },
    },
  }),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

// TODO - add the meta data fetch.
export const berachainMainnetBlockClient = new ApolloClient({
  uri: 'https://api.goldsky.com/api/public/project_clpx84oel0al201r78jsl0r3i/subgraphs/berachain-mainnet-blocks/1.0.0/gn',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-first',
    },
    query: {
      fetchPolicy: 'cache-first',
      errorPolicy: 'all',
    },
  },
})
